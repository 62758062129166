'use client';

import { ReactElement } from 'react';
import { StrapiFormSectionData } from '@/modules/strapiTypes';
import { useNavbarModal } from '@/context/NavbarModalsContext';

interface FormModalButtonProps {
  styleClasses: string;
  formData: StrapiFormSectionData;
  rel: string | undefined;
  displayText: string | undefined;
}

export function FormModalButton({
  styleClasses,
  formData,
  rel,
  displayText,
}: FormModalButtonProps): ReactElement | null {
  const { setFormData } = useNavbarModal();

  const handleClick = () => {
    setFormData(formData);
  };

  return (
    <button className={styleClasses} rel={rel} onClick={() => handleClick()}>
      {displayText}
    </button>
  );
}
