import { StrapiButtonData, StrapiFormSectionData } from '@/modules/strapiTypes';
import Link from 'next/link';
import { StrapiImage } from './StrapiImage';
import { FormModalButton } from '@/strapiComponents/NavbarModal/FormModalButton';

const variantClasses = {
  green: 'bg-custom-green text-white',
  blue: 'bg-custom-blue text-white',
  red: 'bg-custom-red text-white',
  transparent:
    'border border-white bg-transparent text-white hover:bg-white/20 transition-all hover:border-transparent hover:opacity-100',
  grey: 'bg-custom-grey text-white',
  transparent_darkgrey:
    'border border-custom-darkgrey bg-transparent hover:bg-black/10 transition-all hover:border-transparent hover:opacity-100',
  darkgrey: 'border border-custom-darkgrey bg-custom-darkgrey text-white',
  hero: 'border border-white bg-custom-darkgrey text-white hover:bg-white/20 transition-all hover:border-transparent hover:opacity-100',
  transparent_red:
    'group flex flex-row rounded-lg border border-black bg-transparent !p-2 hover:border-custom-red hover:bg-custom-red hover:text-white !font-normal',
};

interface StrapiButtonProps extends StrapiButtonData {
  disabled?: boolean;
}

export function StrapiButton({
  variant,
  display_text,
  url,
  open_in_new_tab,
  rel,
  disabled,
  icon,
  form_section,
}: StrapiButtonProps) {
  const buttonVariant = variant ?? 'green';
  const styleClasses =
    'inline-block p-4 px-8 rounded-md px-3 align-middle cursor-pointer transition-opacity hover:opacity-60 font-semibold text-sm md:text-base ' +
    variantClasses[buttonVariant] +
    ' ' +
    (disabled ? ' cursor-not-allowed' : '');

  return form_section?.data ? (
    <FormModalButton
      formData={form_section?.data as StrapiFormSectionData}
      styleClasses={styleClasses}
      rel={rel}
      displayText={display_text}
    ></FormModalButton>
  ) : url ? (
    <Link
      className={styleClasses}
      href={url ?? ''}
      rel={rel}
      target={open_in_new_tab ? '' : undefined}
    >
      <div className="flex items-center gap-2">
        {!!icon && (
          <StrapiImage
            className="h-8 w-8 rounded-full group-hover:invert"
            strapiImage={icon}
          />
        )}
        {display_text}
      </div>
    </Link>
  ) : (
    <button className={styleClasses} rel={rel}>
      <div className="flex items-center gap-2">
        {!!icon && (
          <StrapiImage
            className="h-8 w-8 rounded-full group-hover:invert"
            strapiImage={icon}
          />
        )}
        {display_text}
      </div>
    </button>
  );
}
