import Image from 'next/image';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getShippingAlternatives } from '@/modules/apiClient';
import {
  CustomerType,
  GetShippingAlternativesRequest,
  ShippingAlternative,
  ShoppingCartProduct,
} from '@/modules/apiTypes';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';

interface ShippingAlternativesProps {
  data: StrapiCheckoutSectionData;
  customerType: CustomerType;
}

export default function ShippingAlternatives({
  data,
  customerType,
}: ShippingAlternativesProps) {
  const {
    shoppingCart,
    displayVat,
    selectedShippingAlternative,
    setSelectedShippingAlternative,
  } = useShoppingCart();
  const [shippingAlternatives, setShippingAlternatives] = useState<
    ShippingAlternative[]
  >([]);

  useEffect(() => {
    if (shoppingCart.content.length > 0) {
      fetchShippingAlternatives(
        shoppingCart.content,
        customerType,
        setShippingAlternatives
      );
    }
  }, [customerType, setSelectedShippingAlternative, shoppingCart.content]);

  useEffect(() => {
    if (
      !selectedShippingAlternative ||
      !shippingAlternatives.includes(selectedShippingAlternative)
    ) {
      setSelectedShippingAlternative(shippingAlternatives[0]);
    }
  }, [
    selectedShippingAlternative,
    setSelectedShippingAlternative,
    shippingAlternatives,
  ]);

  const handleSetShippingAlternative = (
    newAlternative: ShippingAlternative
  ) => {
    setSelectedShippingAlternative(newAlternative);
  };

  return (
    shippingAlternatives.length > 0 && (
      <div className="border-b border-hlr-gunmetal py-10">
        <h2>{data.fields_delivery_option_title}</h2>
        <p className="text-body-hero my-5 max-w-4xl">
          <StrapiRichText richText={data.fields_delivery_option_description} />
        </p>
        <h3 className="mb-4 mt-10">{data.fields_delivery_option_header}</h3>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {shippingAlternatives.map((alternative) => {
            const price = Number(
              displayVat
                ? alternative.product.price_incl_vat
                : alternative.product.price_excl_vat
            );
            return (
              <div
                key={alternative.id}
                className={`relative flex cursor-pointer rounded-xl border-2 p-4 transition-colors hover:border-blue-500 ${
                  alternative.id == selectedShippingAlternative?.id
                    ? 'border-blue-500 shadow-lg'
                    : 'border-custom-grey '
                }`}
                onClick={() => handleSetShippingAlternative(alternative)}
              >
                <div>
                  {alternative.product.images.length > 0 && (
                    <div className="relative h-28 w-28 shrink-0">
                      <Image
                        src={alternative.product.images[0].url}
                        alt={alternative.product.images[0].alt}
                        fill
                      />
                    </div>
                  )}
                  <div className="mt-2 font-raleway text-lg font-bold md:hidden">
                    {`${price} kr`}
                  </div>
                </div>
                <div className="w-full pl-4">
                  <div className="flex w-full flex-row justify-between">
                    <h3>{alternative.product.name}</h3>
                    <h3 className="hidden font-raleway text-xl md:block">
                      {`${price} kr`}
                    </h3>
                  </div>
                  {alternative.product.description && (
                    <div
                      className="text-sm font-light md:text-base"
                      dangerouslySetInnerHTML={{
                        __html: alternative.product.description,
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}

function fetchShippingAlternatives(
  products: ShoppingCartProduct[],
  customerType: CustomerType,
  setShippingAlternatives: Dispatch<SetStateAction<ShippingAlternative[]>>
) {
  const fetch = async () => {
    const data: GetShippingAlternativesRequest = {
      products,
      customer_type: customerType,
    };
    const result = await getShippingAlternatives(data);
    result && setShippingAlternatives(result);
  };
  void fetch();
}
